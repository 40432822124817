<template>
  <div class="dashboard-container">
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="10" align="left">
          <div style="display: flex; width: 70%">
            <el-input
              v-model="searchUser"
              placeholder="请输入用户名称"
              clearable
              @clear="clear"
            />
            <el-input
              v-model="searchPhone"
              style="margin-left: 20px"
              maxlength="11"
              placeholder="请输入手机号"
              clearable
              @clear="clear"
            />
            <el-button
              slot="append"
              type="primary"
              style="margin-left: 5px"
              @click="searchUserList"
              >查询</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
       <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
      <el-table-column align="center" prop="headLogo" label="头像" width="200">
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
                :src="'http://img.c-fol.cn/' + scope.row.headLogo"
                :preview-src-list="[
                  'http://img.c-fol.cn/' + scope.row.headLogo,
                ]"
              />
            </div>
          </template>
        </el-table-column>
      
        <el-table-column
          align="center"
          prop="nickName"
          label="微信昵称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="phone"
          label="微信电话"
          :show-overflow-tooltip="true"
        />
    <el-table-column
          align="center"
          prop="gender"
          label="性别"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.gender | sex }}
          </template>
        </el-table-column>
               <el-table-column
          align="center"
          prop="userName"
          label="用户名称"
          :show-overflow-tooltip="true"
        />
              <el-table-column
          align="center"
          prop="companyName"
          label="公司名称"
          :show-overflow-tooltip="true"
        />
           <el-table-column
          align="center"
          prop="account"
          label="账号"
          :show-overflow-tooltip="true"
        />
           <el-table-column
          align="center"
          prop="userRole"
          label="用户角色"
          :show-overflow-tooltip="true"
        >
         <template slot-scope="scope">
            {{ scope.row.userRole  | dj}}
          </template>
           </el-table-column>
         
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>
          
     
      </el-table>
      <!-- 分页 -->
     <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.pageSize"
      @pagination="getUserList"
    />

    </div>
  </div>
</template>

<script>
import {

 getUserList
} from "../../request/http";
export default {
  data() {
    return {
      list: "", // 用户列表
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      searchUser: "",
      searchPhone: "",
    };
  },
  created() {
     this.getUserList()
  },
  methods: {
        clear() {
      this.queryParams = {}
      this.getUserList()
      this.searchUser = '',
      this.searchPhone = ''
    },
      // 查询用户
    async searchUserList() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        userName: this.searchUser,
        phone: this.searchPhone
      }
      const { data, count } = await getUserList(this.queryParams)
      this.list = data
      this.total = count
    },
    
     
      // 获取用户列表
    async getUserList() {
      const { data, count } = await getUserList(this.queryParams)
      this.list = data
      this.total = count
    },
  },
};
</script>

<style>
</style>